import { render, staticRenderFns } from "./exhibition.vue?vue&type=template&id=51e1ce0b&scoped=true&"
import script from "./exhibition.vue?vue&type=script&lang=js&"
export * from "./exhibition.vue?vue&type=script&lang=js&"
import style0 from "./exhibition.vue?vue&type=style&index=0&id=51e1ce0b&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "51e1ce0b",
  null
  
)

export default component.exports